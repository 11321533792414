<template>
  <div
    eagle-list="header-item"
    class="py-2 subtitle-2"
  >
    <v-tooltip
      v-if="help"
      top
      :style="{ zIndex: $zIndexConfig.listTable }"
    >
      <template v-slot:activator="{on}">
        <v-icon
          v-on="on"
          left
          class="white--text cursor-pointer"
        >
          fa fa-info-circle
        </v-icon>
      </template>
      <div>{{help}}</div>
    </v-tooltip>
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    listKey: String,
    dataConfig: Object,
  },
  computed: {
    label() {
      return this.$t(this.dataConfig.label)
    },
    help() {
      if(!this.dataConfig.help) return null
      return this.$t(this.dataConfig.help)
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>